import React, {useState} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Showcase from "../components/showcase"

var showcaseActive = false;

const artwork_row = (artworks) => {
  console.log("here");
      let artwork_rows = artworks.sort((entry,entryb) => {
      if(entry.title.substr(-6) < entryb.title.substr(-6)){
        return -1
      }  else if(entry.title.substr(-6) > entryb.title.substr(-6)) {
        return 1
      }
    return 0}).reduce((resultArray, item, index) => { 
    
    console.log({item})
          
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.small,
    details:<div className={item.sold}><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow  forceLength={3}  images={row} /> )
    
    return artwork_rows;
}
const renderDescription = (showcase) => {
  if(showcase) {
    return <div style={{textAlign:"center", margin:"20px"}}>A representative selection of artworks sold or no longer available</div>
  }
}

const renderShowcaseButton = (showcase, setShowcaseActive,showcaseActive) => {
  if(showcase) {
    return <h3 className={showcaseActive ? "active" : null} onClick={() => {setShowcaseActive(true)}}>Artist showcase</h3>
  }
}

const renderArtworksButton = (artworks, setShowcaseActive,showcaseActive) => {
  if(artworks) {
    return <h3 className={!showcaseActive ? "active" : null} onClick={() => {setShowcaseActive(false)}}>Available Artworks</h3>
  }
}
const renderStatementButton = (visible,active,setBottomText) => {
  if(visible) {
  return <h3 className={active ? "active" : null} onClick={() => {setBottomText("artistStatement")}}>Artist statement</h3>
  }
}

const renderBioButton = (visible,active,setBottomText) => {
  if(visible) {
  return <h3 className={active ? "active" : null} onClick={() => {setBottomText("bio")}}>Bio</h3>
  }
}


const renderCVButton = (cv) => {
  if(Array.isArray(cv) && cv[0] && cv[0].filename) {
  return <Link to={"https://franceskeevil.frb.io/cv/" + cv[0].filename}><h3>Artist CV (pdf)</h3></Link>
  }
}

const renderWebsiteButton = (website) => {
  if(website) {
  return <Link to={website}><h3>Website</h3></Link>
  }
}
const renderText = (current,artist) => {
  let text = artist[current];
  if(text) {
  return <ImageRow rowType={"largeRow"} 
    images={
      [{type:"text", text: <div><p style={{padding:"36px 20px", fontSize:"18px", whiteSpace: "pre-wrap" }}>{text}</p></div>, span:"12"}] } />
    }
}
const IndexPage = ({pageContext}) => {
  console.log("hi");
  const artworksVisible = pageContext.artist.artworks && pageContext.artist.artworks.length > 0
  const startShowcase = !(artworksVisible) && pageContext.artist.showcaseArtworks && pageContext.artist.showcaseArtworks.length > 0; 
  const [showcaseActive, setShowcaseActive] = useState(startShowcase);
  const [bottomText, setBottomText] = useState("artistStatement");
  let artworks;
  if(showcaseActive) {
    artworks = pageContext.artist ? pageContext.artist.showcaseArtworks : [];
  }
  else {
    artworks = pageContext.artist ? pageContext.artist.artworks : [];
  }
  if(pageContext.artist) {

  return (<div>
  <Layout hero={<Carousel images={pageContext.artist.images.map(image => image.url)} />}>
    <SEO title={pageContext.artist.title} />

    <div >
      <h1 style={{marginBottom:"100px", fontSize:"36px",marginTop:"60px", textAlign:"center",}}>{pageContext.artist.title}</h1>

        <div className={"titles inline"}>
          {renderArtworksButton(artworksVisible, setShowcaseActive, showcaseActive)}
          
          {renderShowcaseButton(pageContext.artist.showcaseArtworks.length > 0, setShowcaseActive, showcaseActive)}
        </div>
      
      {renderDescription(showcaseActive)}
      {artwork_row(artworks)}

<div className={"titles inline"}>
  {renderStatementButton(pageContext.artist.artistStatement, bottomText == "artistStatement", setBottomText)}
  {renderBioButton(pageContext.artist.bio, bottomText == "bio", setBottomText)}
  {renderCVButton(pageContext.artist.artistCv)}
  {renderWebsiteButton(pageContext.artist.website)}
  </div>
  </div>
  {renderText(bottomText,pageContext.artist)}

  </Layout>
</div>)
}
return <div></div>
}

export default IndexPage
